import apiService from '@dishopsaas/dishop-backend-api-service';
import { sendCloudWatchLogs } from '../../utils/logs';
import store from '../../redux/store';
import {
  resetCommande,
  selectSection,
  setIsMobile,
  setNavbarHeight,
  showHorizontalSection,
  showMessageModal,
  updateCommande,
  updateShopIsLoaded,
} from '../../redux/actions';
import _ from 'lodash';
import {
  getOffsetTop,
  isProductDisabled,
  isSectionNotInRange,
} from '../../utils';
import { ORDER_TYPE_DELIVERY } from '../../constants';
import { updateAddressAndShop } from '../../utils/customer-address-and-shops';

export const getClasses = () => {
  const { isHorizontalSection } = store.getState().componentReducer;
  const listSectionClass = 'col-lg-2 col-4 col-md-3 mb-3 pr-4';
  let listProductClass = 'col-lg-10 col-8 col-md-9';
  if (isHorizontalSection) {
    listProductClass = 'col-12 p-0';
  }
  return { listSectionClass, listProductClass };
};

export const displayOrNotHeader = (displayNavBar: boolean) => {
  const header = document.getElementsByTagName('header')[0];
  if (header) {
    if (displayNavBar) {
      header.style.visibility = 'hidden';
    } else {
      header.style.visibility = 'visible';
    }
  }
};

export const resetCommandeIfNeeded = async () => {
  const { userConnected, isUserAnonymous, user } = store.getState().userReducer;
  if (userConnected || isUserAnonymous) {
    const { customerId } = user;
    sendCloudWatchLogs(
      `Resetting commande due to changing shop for customer ${customerId}`
    );
    await apiService.pendingOrdersDelete([customerId, 'commande']);
  } else {
    store.dispatch(resetCommande());
  }
};

type UpdateShopFromLink = {
  shopIdFromLink: string;
  customerAddress: any;
  orderType: string;
};

export const updateShopFromLink = async ({
  shopIdFromLink,
  customerAddress,
  orderType,
}: UpdateShopFromLink) => {
  if (customerAddress && shopIdFromLink) {
    store.dispatch(updateShopIsLoaded(false));
    await updateAddressAndShop(shopIdFromLink, customerAddress, orderType);
  }
};

const isAnyOptionDisabled = (sections, categories, productItems) => {
  return _.some(productItems, (item) => {
    const minChoices = categories[item?.categoryId]?.min;
    const maxChoices = categories[item?.categoryId]?.max;
    const isNotBetweenMinAndMax =
      (_.size(item?.options) < minChoices ||
        _.size(item?.options) > maxChoices) &&
      minChoices < maxChoices;
    if ((item && !categoryExists(categories, item)) || isNotBetweenMinAndMax) {
      return true;
    }
    return _.some(item?.options, (option) => {
      const section = _.find(
        sections,
        (section) => section.key === option?.sectionKey
      );
      return (
        option.productKey &&
        section?.products?.[option.productKey]?.key === option?.productKey &&
        section?.products?.[option.productKey].disabled
      );
    });
  });
};

const categoryExists = (categories, orderItem) => {
  return _.every(orderItem?.options, (option) => {
    const foundCategory =
      orderItem.categoryId && categories?.[orderItem.categoryId];
    const foundOption = _.find(
      foundCategory?.items,
      (item) => item.key === option.key
    );
    const includeSubCategories = foundOption?.subcategories
      ? foundOption?.subcategories?.includes(option?.subcategories)
      : true;
    const isPriceMatching = !option?.isFree
      ? foundOption &&
        (parseFloat(foundOption.price) || 0) === parseFloat(option.price)
      : true;
    return isPriceMatching && includeSubCategories;
  });
};

const isProductToRemove = (sectionProducts, orderProduct) => {
  const includeCategories = sectionProducts?.[orderProduct.key]?.categories
    ? sectionProducts[orderProduct.key]?.categories?.includes(
        orderProduct?.categories
      )
    : true;
  return (
    sectionProducts?.[orderProduct.key] &&
    (isProductDisabled(sectionProducts[orderProduct.key]) ||
      parseFloat(sectionProducts[orderProduct.key].price) !==
        orderProduct.unitPrice ||
      !includeCategories)
  );
};

export const updateDimensions = () => {
  store.dispatch(
    setIsMobile(window.matchMedia('screen and (max-width: 575px)').matches)
  );
  store.dispatch(setNavbarHeight());
  store.dispatch(showHorizontalSection());
};

export const handleScroll = (
  currentListSectionsRef: any,
  displaySectionsNavBar: boolean,
  setDisplaySectionsNavBar: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { sectionsRef } = store.getState().sectionReducer;
  const { navbarHeight } = store.getState().componentReducer;
  const extraTop = 2;
  const needToDisplaySectionsNavBar =
    currentListSectionsRef &&
    window.scrollY >= currentListSectionsRef.offsetTop - extraTop;
  if (needToDisplaySectionsNavBar && !displaySectionsNavBar) {
    setDisplaySectionsNavBar(true);
  } else if (!needToDisplaySectionsNavBar && displaySectionsNavBar) {
    setDisplaySectionsNavBar(false);
  }
  store.dispatch(setNavbarHeight());

  let offsetTop = 0;
  let newSectionKey = null;
  const isBottomPage =
    window.innerHeight + window.scrollY >= document.body.offsetHeight;
  _.map(sectionsRef, (sectionRef, sectionKey) => {
    if (
      sectionRef &&
      (window.scrollY >=
        getOffsetTop(sectionRef) - parseInt(navbarHeight, 10) - extraTop ||
        isBottomPage) &&
      getOffsetTop(sectionRef) > offsetTop
    ) {
      offsetTop = getOffsetTop(sectionRef);
      newSectionKey = sectionKey;
    }
  });

  if (newSectionKey) {
    store.dispatch(selectSection(newSectionKey));
  }
};

type RemoveItempFromCommandeParams = {
  orderType: string;
  prevOrderType?: string;
  commande: any;
  shopId: string;
  shopIsLoaded: boolean;
  networkFees: any;
  sections: any;
  categories: any;
  userConnected: boolean;
  isUserAnonymous: boolean;
  customerId: string;
};

export const removeItemFromCommande = ({
  orderType,
  prevOrderType,
  commande,
  shopId,
  shopIsLoaded,
  networkFees,
  sections,
  categories,
  userConnected,
  isUserAnonymous,
  customerId,
}: RemoveItempFromCommandeParams) => {
  const orderTypeCheck =
    !_.isEqual(orderType, prevOrderType) &&
    (_.isEqual(orderType, ORDER_TYPE_DELIVERY) ||
      _.isEqual(prevOrderType, ORDER_TYPE_DELIVERY));
  const newOrder = _.cloneDeep(commande);
  let isShopChanged = false;
  _.map(commande, async (commandeProduct, key) => {
    isShopChanged = commandeProduct.shopId !== shopId;
    if (
      shopIsLoaded &&
      (isShopChanged || (networkFees?.delivery?.productFees && orderTypeCheck))
    ) {
      await resetCommandeIfNeeded();
      return;
    }
    const section = sections?.find((existingSection) => {
      return (
        existingSection.key === commandeProduct.sectionKey &&
        existingSection?.products?.[commandeProduct.key]
      );
    });
    const productToRemove =
      isProductToRemove(section?.products, commandeProduct) ||
      isAnyOptionDisabled(sections, categories, commandeProduct?.items);
    if (!section || productToRemove || isSectionNotInRange(section))
      delete newOrder[key];
    if (!_.isEqual(newOrder, commande)) {
      if (userConnected || isUserAnonymous) {
        if (_.isEmpty(newOrder)) {
          await apiService.pendingOrdersDelete([customerId, 'commande']);
        } else {
          await apiService.pendingOrdersUpsert(
            [customerId, 'commande'],
            newOrder,
            { updateMode: 'replace' }
          );
        }
      } else {
        store.dispatch(
          _.isEmpty(newOrder) ? resetCommande() : updateCommande(newOrder)
        );
      }
      if (!isShopChanged) {
        store.dispatch(
          showMessageModal(
            `Il existe un ou plusieurs articles dans votre panier qui ne sont plus disponibles.`
          )
        );
      }
    }
  });
};
